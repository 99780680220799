<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="header-title me-5">
              <h4 class="card-title">Меню</h4>
            </div>
          </div>
          <div class="card-body">
            <div v-if="menus !== null" class="table-responsive">
              <table id="user-list-table" class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th data-type="number" v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <tr v-for="menu in menus" :key="menu.id">
                  <td>{{ menu.names.ru }}</td>
                  <td>{{ menu.names.uz }}</td>
                  <td>{{ menu.names.eng }}</td>
                  <td>{{ menu.description.uz }}</td>
                  <td>{{ menu.description.ru }}</td>
                  <td>{{ menu.description.eng }}</td>
                  <td class="text-center">
                    <div class="dropdown">
                      <button
                        class="btn btn-link btn-primary"
                        role="button"
                        data-bs-toggle="modal"
                        data-bs-target="#messages"
                        @click="editMenu(menu)"
                      >
                        Редактировать
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      style="display: none"
      mainClass="fade"
      :tabindex="-1"
      id="messages"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body v-if="menuData !== null" class="px-5">
        <h1 class="text-center">Сообщения</h1>
        <Form class="mt-4" v-slot="{ meta }" @submit="addDescription">
          <div class="row">
            <div class="col-md-4">
              <label for="text-btn-ru" class="form-label">Название кнопки (русский)</label>
              <Field id="text-btn-ru" type="text" class="form-control" name="names[ru]" rules="required" v-model="menuData.names.ru" />
              <ErrorMessage class="text-danger" name="names[ru]" />
            </div>
            <div class="col-md-4">
              <label for="text-btn-uz" class="form-label">Название кнопки (английский)</label>
              <Field id="text-btn-uz" type="text" class="form-control" name="names[eng]" rules="required" v-model="menuData.names.eng" />
              <ErrorMessage class="text-danger" name="names[eng]" />
            </div>
            <div class="col-md-4">
              <label for="text-btn-uz" class="form-label">Название кнопки (узбекский)</label>
              <Field id="text-btn-uz" type="text" class="form-control" name="names[uz]" rules="required" v-model="menuData.names.uz" />
              <ErrorMessage class="text-danger" name="names[uz]" />
            </div>
          </div>

          <div>
            <label for="text_ru" class="form-label">Текст на русском</label>
            <Field
              v-slot="{ field }"
              type="text"
              name="descriptions[ru]"
              rules="required"
              v-model="menuData.description.ru"
            >
              <textarea v-bind="field" id="text_ru" class="form-control"></textarea>
            </Field>
            <ErrorMessage class="text-danger" name="descriptions[ru]" />
          </div>

          <div>
            <label for="text-uz" class="form-label mt-4">Текст на узбекском</label>
            <Field
              v-slot="{ field }"
              type="text"
              name="descriptions[uz]"
              rules="required"
              v-model="menuData.description.uz"
            >
              <textarea v-bind="field" id="text_uz" class="form-control"></textarea>
            </Field>
            <ErrorMessage class="text-danger" name="descriptions[uz]" />
          </div>

          <div>
            <label for="text-eng" class="form-label mt-4">Текст на английском</label>
            <Field
              v-slot="{ field }"
              type="text"
              name="descriptions[eng]"
              rules="required"
              v-model="menuData.description.eng"
            >
              <textarea v-bind="field" id="text_eng" class="form-control"></textarea>
            </Field>
            <ErrorMessage class="text-danger" name="descriptions[eng]" />
          </div>

          <div class="text-center my-5">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
          </div>
        </Form>
      </model-body>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/bootstrap/modal/modal'
import modelHeader from '@/components/bootstrap/modal/model-header'
import modelBody from '@/components/bootstrap/modal/model-body'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { getMenus, updateMenu, createDescription } from '@/services/menu.service'

export default {
  name: 'ButtonsIndex',
  data: () => ({
    tableHeaders: [
      'Название кнопки (русский)',
      'Название кнопки (узбекский)',
      'Название кнопки (английский)',
      'Текст кнопки (русский)',
      'Текст кнопки (узбекский)',
      'Текст кнопки (английский)',
      'Действия'
    ],
    menus: null,
    menuData: null
  }),
  components: {
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    editMenu (row) {
      this.menuData = null
      this.menuData = row
    },
    async fetchMenus () {
      const { data: menuResponse } = await getMenus()
      this.menus = menuResponse.data.map(menu => ({ ...menu, editable: false }))
    },
    async addDescription (values) {
      console.log(values)
      const formData = new FormData()

      formData.append('menu_id', this.menuData.id)
      Object.entries(values).forEach(([name, field]) => {
        formData.append(name, field)
      })

      await createDescription(formData)
      await updateMenu(formData)

      window.location.reload()
    }
  },
  created () {
    this.fetchMenus()
  }
}
</script>

<style lang="scss" scoped>
  .user-list-table {
    table-layout: fixed;
  }

  td {
    white-space: break-spaces;

    button {
      white-space: normal;
    }
  }
</style>
