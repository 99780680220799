import ApiClient from '@/api'
import Menu from '@/models/Menu'
import axios from '@/plugins/axios'

const api = new ApiClient(Menu.modelName)

const getMenus = async (params) => {
  const { data: menus } = await api.getAll(params)
  return menus
}

const createMenu = async (formData) => {
  const { data: menu } = await api.create(formData)
  return menu
}

const updateMenu = async (formData) => {
  const { data: menu } = await api.update(formData)
  return menu
}

const removeMenu = async (id) => {
  const { data: menu } = await api.delete(id)
  return menu
}

const createDescription = async (formData) => {
  const { data: description } = await axios.post(`/admin/${Menu.modelName}/description`, formData)
  return description
}

const getNeighbours = async () => {
  const { data: neighbours } = await axios.get(`/admin/${Menu.modelName}/neighbours`)
  return neighbours
}

const getParents = async () => {
  const { data: parents } = await axios.get(`/admin/${Menu.modelName}/parents`)
  return parents
}

export {
  getMenus,
  createMenu,
  updateMenu,
  removeMenu,
  createDescription,
  getNeighbours,
  getParents
}
